<template>
  <div class="mapMain">
    <div id="amap" ref="amap"></div>
      <zoom ref="zoom" v-show="false"/>
      <toolbar ref="Toolbar" v-show="false" />
      <!-- <search :modeal='modeal' ref="search"/> -->
      <rightTool
          ref="rightTool"
          @closeDraw="closeDraw"
          @draw="draw"
          v-show="false"
        />
      <toolInfo ref="toolInfo" v-show="false"/>
      <layerWms :layers="layers" ref="layerWms" v-show="false"/>
      <markInfo ref="markInfo" v-show="false"/>
      <layerEdit ref="layerEdit" v-show="false"/>
  </div>
</template>

<script>
import zoom from '@/views/mapTool/question/zoom'
import Toolbar from '@/views/mapTool/leftTool/Toolbar'
import search from '@/views/mapTool/leftTool/search'
import rightTool from '@/views/mapTool/rightTool/index'
import toolInfo from '@/views/mapTool/layer/toolInfo'
import { mapMixin } from '@/common/amapInit'
import layerWms from '@/views/mapTool/layer/layerWms'
import markInfo from '@/views/mapTool/layer/markInfo'
import layerEdit from '@/views/mapTool/layer/layerEdit'
import { userPath } from '@/apis/commonType'
export default {
  name: 'Query',
  components: {
   zoom,
   Toolbar,
   search,
   rightTool,
   toolInfo,
   layerWms,
   markInfo,
   layerEdit
  },
  mixins: [mapMixin],
  props: {
    modeal: {
      type: String,
      default: ''
    },
    layerName: {
      type: String,
      default: ''
    },
    layers:{
      type: String,
      default: "'patrol_events','patrol_equipments','patrol_lines','patrol_networks','user'"
    },

  },
  data() {
    return {
      mouseTool:null,
      pLoaction: false,
      markerHighLight:{},
      userpolyline:null,
      polyline1Add:null,
      end:require('@/assets/end.png'),
      start:require('@/assets/start.png'),
      labelsLayerUser:null
    }
  },
  computed: {},
  watch: {},
  mounted() {
    let that=this
        that.initMap()
      
  },

  methods: {
   
    setCenterFun(point){
        this.map.setCenter(point)
    },
    
    SetMarkerHighLight(e){
      this.map.remove(this.markerHighLight)
      this.markerHighLight = new AMap.CircleMarker({
        center:e,
        radius:15,//3D视图下，CircleMarker半径不要超过64px
        strokeColor:'white',
        strokeWeight:2,
        strokeOpacity:0.5,
        fillColor:'red',
        fillOpacity:0.5,
        zIndex:10,
        bubble:true,
        clickable: true
      })
      this.markerHighLight.setMap(this.map)
    },
    setCenter(arr){
      this.map.setCenter(arr)
    },
    setUserPath(e){
       if(this.userpolyline){
          this.map.remove(this.userpolyline)
        }
        if(this.labelsLayerUser){
           this.map.remove(this.labelsLayerUser)
        }
        
        let that=this

        userPath({userId:e.id}).then(res => {
          let list=res.data.records
           if(list){
              let locations=list[0].locations
              if(locations&&locations.length>0){
          
                this.userpolyline = new AMap.Polyline({
                    path: locations,
                    isOutline: true,
                    outlineColor: '#ffeeff',
                    borderWeight: 2,
                    strokeColor: "#3366FF",
                    strokeOpacity: 1,
                    strokeWeight: 4,
                    strokeStyle: "solid",
                    lineJoin: 'round',
                    lineCap: 'round',
                    zIndex: 2,
                  })
                  this.map.add(this.userpolyline)

                    let markers=[]
                     let startPoint = new AMap.LabelMarker({
                        position: locations[0],
                        zIndex: 20,
                        icon: {
                          size: [19, 30],    // 图标尺寸
                          anchor: 'bottom-center',
                          image: that.start,  // Icon的图像地址
                        },                        
                      })       
                      markers.push(startPoint)
                      let l=locations.length-1
                      console.log(locations[l])
                      let endPoint = new AMap.LabelMarker({
                        position: locations[l],
                        zIndex: 21,
                        icon: {
                          size: [19, 30],    // 图标尺寸
                          anchor: 'bottom-center',
                          image: that.end, 
                        },
                        text: {
                          content: list[0].username,
                          direction: 'right',
                          offset: [0, 0,0,0],
                          style: {
                            fontSize: 12,
                            fillColor: '#fff',
                            strokeColor: '#000',
                            strokeWidth: 2
                          }
                        }
                      })      
                    //   markers.push(endPoint)
                    //   that.labelsLayerUser = new AMap.LabelsLayer({
                    //     zooms: [0, 20],
                    //     zIndex: 160,
                    //     collision: false,
                    //     allowCollision: false,
                    // });
                    //   that.labelsLayerUser.add(markers)
                    //   that.map.add(that.labelsLayerUser)


              }
           }
        })
    },
   
    clearLocation(){
       this.pLoaction = false
       this.drawState = false
       if(this.drawlays){
         this.map.remove(this.drawlays)
         this.mouseTool.close(true)
       }       
    },
    getLocation(type) {
      this.drawState = true
      this.pLoaction = true
      if(type=='line'){
        this.draw('polyline')  
      }else if(type=='polygon'){
        this.draw('polygon')  
      }else{
        this.draw('marker')  
      }       
    },
   
    initMap() {
      let that = this
      let map = new AMap.Map('amap', {
        ...that.mapConfig
      })
      that.map = map

      // that.map.plugin(["AMap.Scale"],function(){
      //     let scale = new AMap.Scale();
      //     that.map.addControl(scale);
      // });
      // localStorage.setItem("mapStyle", 'grey');
      // document.body.classList.add("black");
      //   this.$store.commit("colorChange", "black");

      that.map.plugin(["AMap.MouseTool"],function(){
         that.mouseTool = new AMap.MouseTool(that.map)
         that.drawEnd()  
     });
           
      that.map.on('complete', function() {

            that.$refs.zoom.init()
            that.getMark()
            that.map.on('click', e => {
              if (!that.drawState) {
                that.$refs.markInfo.getFeatrue(e.lnglat)
              }
            })
            that.map.on('mousedown', e => {
              if (that.drawState) {
                that.onClick(e)
              }
            })
            that.$refs.layerWms.wmsLayer()



      })
      this.$refs.rightTool.MapChangeFun("darkblue")
    },
  }
}
</script>
<style lang="scss" src="./skin.scss"></style>
<style lang="scss" src="./common.scss"></style>

<style lang="scss" scoped>
.subMap {
  width: 100%;
  height: 100%;
  
}
.mapMain{position: relative;height: 100%}
#amap{width: 100%;height: calc(100vh);}

::v-deep .amap-scalecontrol {
  color: var(--font-color-skin);
  background: none !important;
  left: 0.7rem !important;
  bottom: 0.1rem !important;
}
::v-deep .tool {
  display: flex;
  top: 0.1rem;
  li {
    margin-left: 0.1rem;
    margin-top: 0;
  }
}
::v-deep .bottom {
  width: 65%;
}

::v-deep {
  .rightTool {
    right: 4.6rem !important;
    height: 100%;
  }

  .toolbar {
    left: 4.9rem !important;
    bottom: 0.1rem;
  }
  .MapChangeClass {
    bottom: 0.1rem;
  }
  .legend {
    bottom: 0.9rem;
  }
}
</style>

