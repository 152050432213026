<template>
  <el-dialog
    title="标注"
    :visible.sync="dialogVisible"
    width="300px"
    :before-close="handleClose"
    :show-close="false"
    :close-on-click-modal="false"
    custom-class="layerDailog"
  >
    <div>
      <div style="margin-bottom:10px" v-if="item.type=='point'">
         <el-radio v-model="item.markType" label="1">入钻造斜</el-radio>
        <el-radio v-model="item.markType" label="2">出钻造斜</el-radio>
      </div>
      <el-input
        v-model="item.remark"
        maxlength="150"
        type="textarea"
        autosize
        placeholder="请输入内容"
      >
      </el-input>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" size="small" plain @click="handleClose()">删 除</el-button>
      <el-button type="primary" size="small" @click="save()">确 定</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { saveMake, delMake } from '@/apis/commonType'
export default {
  components: {},
  data() {
    return {
      dialogVisible: false,
      item: {},
      markType:'1'
    }
  },
  methods: {
    initialize(e) {
      this.item = e.item
      this.dialogVisible = true
    },
    save() {
      let p = {
        ...this.item
      }
      let that = this
      saveMake(p).then(res => {
        that.dialogVisible = false
        that.$message.success(res.msg)
        that.$parent.clearDraw()
      })
    },
    handleDel() {
      let p = { uuid: this.item.uuid }
      let that = this
      delMake(p).then(res => {
        that.dialogVisible = false
        that.$message.success(res.msg)
        that.$parent.clearDraw()
      })
    },
    //关闭
    handleClose() {
      if (this.item.uuid) {
        this.handleDel()
      } else {
        this.dialogVisible = false
        this.$parent.clearDraw()
      }
    }
  }
}
</script>

<style lang="scss" scoped></style>
