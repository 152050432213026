import axios from '@/common/js/request'

export function constructionTeam_relationDept(data){
    const constructionTeam_relationDept = axios({
        url: '/constructionTeam/relationDept',
        method: 'post',
        data:data
    })
    return constructionTeam_relationDept
}

export function constructionTeam_page(data){
    const constructionTeam_page = axios({
        url: '/constructionTeam/page',
        method: 'get',
        params:data
    })
    return constructionTeam_page
} 
export function user_selectSysDeptTreeList(data){
    const user_selectSysDeptTreeList = axios({
        url: '/dept/page',
        method: 'post',
        data:data
    })
    return user_selectSysDeptTreeList
}
export function constructionTeam_accountSetup(data){
    const constructionTeam_accountSetup = axios({
        url: '/constructionTeam/accountSetup',
        method: 'get',
        params:data
    })
    return constructionTeam_accountSetup
}

export function constructionTeam_save(data){
    const constructionTeam_save = axios({
        url: '/constructionTeam/save',
        method: 'post',
        data:data
    })
    return constructionTeam_save
}
export function constructionTeam_getDetail(data){
    const constructionTeam_getDetail = axios({
        url: '/constructionTeam/getDetail',
        method: 'get',
        params:data
    })
    return constructionTeam_getDetail
}
export function constructionTeam_update(data){
    const constructionTeam_update = axios({
        url: '/constructionTeam/update',
        method: 'post',
        data:data
    })
    return constructionTeam_update
}